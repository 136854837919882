// import PropTypes from "prop-types"
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SlideShow from "../components/slider"
import Blurb from "../components/blurb"
import Seo from "../components/seo"
import CardBody from "../components/bodyCards"
import Header from "../components/header"
import { Row, Container } from "react-bootstrap"

export const query = graphql`
  {
    keyLogo: file(relativePath: { eq: "key-logo-full.png" }) {
      childImageSharp {
        fluid(maxWidth: 2140) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    keyFobGreen: file(relativePath: { eq: "key-fob-green.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    keyFobHand: file(relativePath: { eq: "key-fob-hand.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    keyRemote: file(relativePath: { eq: "key-remote.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    keySand: file(relativePath: { eq: "key-sand.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const KeyRemote = ({ data }) => {
  const phoneNum = process.env.GATSBY_PHONE_NUMBER
  const keyHand = data.keyFobHand.childImageSharp.fluid
  const keyLogo = data.keyLogo.childImageSharp.fluid
  const keySand = data.keySand.childImageSharp.fluid
  const keyRemote = data.keyRemote.childImageSharp.fluid

  const content = [
    {
      title: "Long Term Coverage",
      description: "Up to 5 years of coverage.",
      image: `${keyHand.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
    {
      title: "The Easy Solution",
      description: "Misplaced, stolen or broken keys & fobs.",
      image: `${keySand.src}`,
      button: "Call Now",
      phone: `${phoneNum}`,
    },
    {
      title: "Save Money",
      description: "15 Day Money Back Guarantee.",
      button: "Call Now",
      image: `${keyRemote.src}`,
      phone: `${phoneNum}`,
    },
  ]

  const text =
    "Todayʼs vehicles come with sophisticated keys and remotes and the cost to repair, replace or reprogram is extremely expensive. Feel secure in not having to incur this costly expense."
  const cardContent = {
    1: {
      img: data.keyFobGreen.childImageSharp.fluid,
      alt: "Green Key Fob",
      list: [
        "Misplaced keys and/or fobs",
        "Stolen keys and fobs",
        "Broken keys and fobs",
      ],
      header: "Secure Ride Key and Remote is the easy solution for:",
    },
    2: {
      img: data.keyFobHand.childImageSharp.fluid,
      alt: "",
      list: [
        "Terms up to 5 years",
        "Choose how many keys you want",
        "Choose how many fobs you want",
      ],
      header: "You choose whats right for you.",
    },
  }
  return (
    <Layout
      className="index-container mt-1"
      pageInfo={{ pageName: "key-remote" }}
    >
      <Seo
        title="Keys and Remotes"
        keywords={[`protection`, `vehicle`, `secure`, `safe`]}
      />
      <SlideShow data={content} />
      <Header logo={keyLogo.src} />
      <Blurb text={text} />
      <Container fluid>
        <Row>
          <CardBody content={cardContent} />
        </Row>
      </Container>
    </Layout>
  )
}

export default KeyRemote
